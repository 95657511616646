import { useGameContext } from "../context/useGameContext";

export const CartSummaryModal = ({
  summaryModal,
  closeSummaryModal,
  onSubmit,
  handleSubmit,
  loadingCartSubmission,
}: any) => {
  const { playerData, cartValue, markers } = useGameContext();

  return (
    <section className={`modal ${summaryModal ? "is-active" : ""}`}>
      <div className="modal-background"></div>
      <div className="modal-card has-text-left">
        <header className="modal-card-head">
          <div className="modal-card-title has-text-centered">
            Potwierdź Twoje dane:
          </div>
        </header>

        <div className="modal-card-body">
          <div className="content">
            <p>
              <span className="has-text-weight-bold">Imię:</span>{" "}
              {playerData.firstName}
            </p>
            <p>
              <span className="has-text-weight-bold">Nazwisko:</span>{" "}
              {playerData.lastName}
            </p>
            <p>
              <span className="has-text-weight-bold">Email:</span>{" "}
              {playerData.email}
            </p>
            <p>
              <span className="has-text-weight-bold">Telefon:</span>{" "}
              {playerData.phone}
            </p>
            {playerData.city && (
              <p>
                <span className="has-text-weight-bold">Miasto:</span>{" "}
                {playerData.city}
              </p>
            )}
            <p>
              <span className="has-text-weight-bold">Ilość:</span>{" "}
              {markers.length}
            </p>
            <p>
              <span className="has-text-weight-bold">Wartość zamówienia:</span>{" "}
              {cartValue.toFixed(2)} PLN
            </p>
          </div>
        </div>

        <footer className="modal-card-foot">
          {loadingCartSubmission ? (
            <span className="loader"></span>
          ) : (
            <>
              <button
                className="btn-cart-summar-amend button"
                aria-label="close"
                onClick={closeSummaryModal}
                disabled={loadingCartSubmission}
              >
                Popraw Dane
              </button>
              <button
                className="btn-cart-summary-accept button is-primary"
                aria-label="close"
                type="submit"
                form="hook-form"
                onClick={handleSubmit(onSubmit)}
                disabled={loadingCartSubmission}
              >
                Przejdź do płatności
              </button>
            </>
          )}
        </footer>
      </div>
    </section>
  );
};
