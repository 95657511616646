import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useGameContext } from "../context/useGameContext";
import { useAuthContext } from "../context/useAuthContext";
import { GameInstructionsModal } from "./GameInstructionsModal";

export const Navbar = () => {
  const location = useLocation();
  const { cartValue, markers } = useGameContext();
  const { getStatute } = useAuthContext();

  const cartIcon = require("../assets/icons/icon-cart-64.png");
  const logo = "/logo.png";

  const [isActive, setIsActive] = useState(false);

  const [instructions, setInstructions] = useState(false);

  const toggleInstructions = () => {
    setInstructions(!instructions);
  };

  const handleBurgerClick = () => {
    setIsActive(!isActive);
  };

  let navbarClass = "";

  switch (location.pathname) {
    case "/verification":
      navbarClass = "navbar-not-display";
      break;
    case "/":
      navbarClass = "navbar-color-background";
      break;
    case "/lobby":
      navbarClass = "navbar-white-background";
      break;
    case "/game":
      navbarClass = "navbar-color-background";
      break;
    case "/cart":
      navbarClass = "navbar-white-background";
      break;
    case "/adminDashboard":
      navbarClass = "navbar-not-display";
      break;

    default:
      if (/^\/adminDashboard\//.test(location.pathname)) {
        navbarClass = "navbar-not-display";
      } else {
        navbarClass = "navbar-white-background";
      }
      break;
  }

  return (
    <nav
      className={`navbar  
      ${navbarClass}
      `}
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <Link to="/" className="navbar-item logo">
          <img src={logo} alt="logo" className="" />
        </Link>
        {markers.length > 0 && (
          <Link to="/cart" className="navbar-item cartIcon is-hidden-desktop">
            <img src={cartIcon} alt="cart icon" />
            <div className="numberCart">{markers.length}</div>
          </Link>
        )}
        <a
          role="button"
          className={`navbar-burger ${isActive ? "is-active" : ""}`}
          aria-label="menu"
          aria-expanded={isActive}
          onClick={handleBurgerClick}
          data-target="navbarData"
          href="#/"
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </a>
      </div>

      <div
        className={`navbar-menu ${isActive ? "is-active" : ""}`}
        id="navbarData"
      >
        <div className="navbar-start">
          <p
            className="navbar-item navbar-instrukcja"
            onClick={() => {
              toggleInstructions();
              handleBurgerClick();
            }}
          >
            Jak wziąć udział?
          </p>
          <Link
            to="/"
            onClick={() => {
              getStatute();
              handleBurgerClick();
            }}
            className="navbar-item"
          >
            Regulamin
          </Link>
        </div>

        <div className="navbar-end ">
          <div className=" has-dropdown is-hoverable is-hidden-touch">
            {markers.length > 0 && (
              <>
                <Link to="/cart" className="navbar-item ">
                  <div className="navbar-item ">{cartValue} PLN</div>
                  <img src={cartIcon} alt="cart icon" />
                  <div className="numberCart">{markers.length}</div>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
      <GameInstructionsModal
        toggleInstructions={toggleInstructions}
        instructions={instructions}
        setInstructions={setInstructions}
      />
    </nav>
  );
};
