import React, { createContext, useState, useEffect, ReactNode } from "react";
import {
  Order,
  MarkersArray,
  AllGames,
  AllPlayersType,
  UserInfoType,
  AuthContextValue,
} from "../types/adminDataTypes";
import { apiMongo } from "../api/apiData";

const AuthContext = createContext<AuthContextValue | undefined>(undefined);

type AuthProviderTypes = {
  children: ReactNode;
};

export const AuthProvider: React.FC<AuthProviderTypes> = ({ children }) => {
  const [userInfo, setUserInfo] = useState<UserInfoType | null>(null);
  const [allOrders, setAllOrders] = useState<Order[]>([]);
  const [allMarkers, setAllMarkers] = useState<MarkersArray[]>([]);
  const [allGames, setAllGames] = useState<AllGames[]>([]);
  const [allPlayers, setAllPlayers] = useState<AllPlayersType[]>([]);
  const [statute, setStatute] = useState("");
  const [policy, setPolicy] = useState("");
  const [bannerTexts, setBannerTexts] = useState({
    title: "",
    description: "",
  });

  useEffect(() => {
    const storedData = localStorage.getItem("userInfo");
    if (storedData) {
      const userData = JSON.parse(storedData);
      setUserInfo(userData);
    }

    getBannerText();
  }, []);

  const getAllPlayers = async () => {
    await apiMongo
      .get(`${process.env.REACT_APP_MONGO_URL}/game/players`, {})
      .then((response) => {
        setAllPlayers(response.data);
      })
      .catch((error) => console.log(error));
  };

  const getAllOrders = async () => {
    await apiMongo
      .get(`${process.env.REACT_APP_MONGO_URL}/game/orders`, {})
      .then((response) => {
        setAllOrders(response.data);
      })
      .catch((error) => console.log(error));
  };

  const getAllMarkers = async () => {
    await apiMongo
      .get(`${process.env.REACT_APP_MONGO_URL}/game/markers`, {})
      .then((response) => {
        setAllMarkers(response.data);
      })
      .catch((error) => console.log(error));
  };

  const getAllGames = async () => {
    await apiMongo
      .get(`${process.env.REACT_APP_MONGO_URL}/game/newgame`, {})
      .then((response) => {
        setAllGames(response.data);
      })
      .catch((error) => console.log(error));
  };

  const getStatute = async () => {
    await apiMongo
      .get(`${process.env.REACT_APP_MONGO_URL}/documents/regulamin`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const file = new Blob([response.data], {
          type: "application/pdf",
        });
        console.log("file", file);
        const fileURL = URL.createObjectURL(file);
        const newWindow = window.open(fileURL);

        if (!newWindow) {
          console.error(
            "Failed to open new window. Pop-up blocker may be preventing it."
          );
        } else {
          newWindow.addEventListener("unload", () => {
            URL.revokeObjectURL(fileURL);
          });
        }
      })
      .catch((error) => console.error(error));
  };

  const getPolicy = async () => {
    await apiMongo
      .get(`${process.env.REACT_APP_MONGO_URL}/documents/polityka`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const file = new Blob([response.data], {
          type: "application/pdf",
        });

        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((error) => console.error(error));
  };

  const getBannerText = async () => {
    await apiMongo
      .get(`${process.env.REACT_APP_MONGO_URL}/documents/bannertexts`)
      .then((response) => setBannerTexts(response.data))
      .catch((error) => console.log(error));
  };

  const contextValue: AuthContextValue = {
    userInfo,
    setUserInfo,
    allOrders,
    allMarkers,
    allGames,
    allPlayers,
    getAllOrders,
    getAllMarkers,
    getAllGames,
    getAllPlayers,
    setAllGames,
    statute,
    setStatute,
    getStatute,
    policy,
    setPolicy,
    getPolicy,
    bannerTexts,
    setBannerTexts,
    getBannerText,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthContext;
