import { useEffect, useRef, useState } from "react";
import { Turnstile } from "@marsidev/react-turnstile";
import type { TurnstileInstance } from "@marsidev/react-turnstile";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useTurnstileContext } from "../context/useTurnstileContext";

export const TurnstilePage = () => {
  const turnstileRef = useRef<TurnstileInstance>();
  const navigate = useNavigate();
  const location = useLocation();

  const { setIsSucessValidation } = useTurnstileContext();

  const [tokenTurnstile, setTokenTurnstile] = useState<string | null>(null);

  const logo = "/logo.png";

  useEffect(() => {
    const verifyToken = async () => {
      await axios
        .post(
          `${process.env.REACT_APP_MONGO_URL}/verify`,
          JSON.stringify(tokenTurnstile)
        )
        .then((res) => {
          console.log("res", res);
          if (res.status === 200) {
            setIsSucessValidation(true);
            const from = location.state?.from?.pathname || "/";
            navigate(from, { replace: true });

            turnstileRef.current?.remove();
          }
        })
        .catch((err) => console.error("Turnstile error", err));
    };

    verifyToken();
  }, [tokenTurnstile]);

  return (
    <div className="">
      <div className="turnstile-page">
        <img src={logo} alt="Map Game logo" className="mb-6" />
        <h1 className="subtitle mb-5">Sprawdzamy czy jesteś człowiekiem ;)</h1>
        <p className=" mb-5">Jeśli nie przekieruje Ciebie, odśwież stronę!</p>
        <Turnstile
          as="aside"
          siteKey="0x4AAAAAAARvQv9paWAWZHwl"
          options={{
            theme: "light",
            language: "pl-pl",
          }}
          scriptOptions={{
            appendTo: "body",
          }}
          ref={turnstileRef}
          onSuccess={setTokenTurnstile}
          onError={() => console.log("TURNSTILE ERROR")}
        />
      </div>
    </div>
  );
};
