export const ShelterElement = () => {
  return (
    <div className="columns shelterBox">
      <div className="column shelter-image"></div>
      <div className="column ">
        <div className="box">
          <p className="title is-4 font-anton has-text-weight-light is-uppercase">
            Kupujemy karmę dla zwierzaków!
          </p>
          <p></p>
          <p className="subtitle is-6">
            Biorąc udział w naszych konkursach wspierasz zwierzaki w
            schroniskach. Znaczna część zebranych środków zostaje przeznaczona
            na karmę i inne potrzebne rzeczy dla schronisk!
          </p>
          <p className="subtitle is-6">
            Znasz miejsce, które Twoim zdaniem szczególnie potrzebuje wsparcia ?
            Daj nam znać: <strong>kontakt@mapgame.pl</strong>
          </p>
          <p>Dzięki!</p>
        </div>
      </div>
    </div>
  );
};
