import { useState } from "react";
import { useAuthContext } from "../../context/useAuthContext";

export const AdminNavbar = () => {
  const { setUserInfo } = useAuthContext();
  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const hangdleLogout = () => {
    console.log("Wylogowano");
    setUserInfo(null);
    localStorage.removeItem("userInfo");
  };

  return (
    <nav
      className="navbar has-background-grey-darker"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <a className="navbar-item has-text-white" href="/">
          Map Game
        </a>
        <button
          className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
          aria-label="menu"
          aria-expanded="false"
          onClick={toggleMenu}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div className={`navbar-menu ${isActive ? "is-active" : ""}`}>
        <div className="navbar-end">
          <button className="button is-warning" onClick={hangdleLogout}>
            Wyloguj
          </button>
        </div>
      </div>
    </nav>
  );
};
