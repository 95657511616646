import { useCookieContext } from "../context/useCookieContext";

export const CookiesModal = () => {
  const {
    isCookiesModalOpen,
    setIsCookiesModalOpen,
    setIsCookiesPreferenceModalOpen,
    setCookie,
  } = useCookieContext();

  const handleCookiesSubmit = () => {
    const preferences = {
      necessary: true,
      analytics: true,
      marketing: true,
    };

    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

    setCookie("userConsent", JSON.stringify(preferences), {
      path: "/",
      expires: oneYearFromNow,
    });

    setIsCookiesModalOpen(false);
  };

  const handleChangeCookies = () => {
    setIsCookiesModalOpen(false);
    setIsCookiesPreferenceModalOpen(true);
  };

  return (
    <section className={`modal ${isCookiesModalOpen ? "is-active" : ""}`}>
      <div
        className="modal-background"
        onClick={() => {
          setIsCookiesPreferenceModalOpen(!setIsCookiesPreferenceModalOpen);
        }}
      ></div>
      <div className="modal-card">
        <div className="modal-card-head">
          <p className="modal-card-title">Lubisz ciasteczka?</p>
        </div>
        <div className="modal-card-body">
          <p className="is-size-7">
            Podczas przeglądania naszej strony, chcielibyśmy wykorzystywać pliki
            cookies tak, aby była bezpieczna i dostosowana do Twoich potrzeb, a
            także korzystać z narzędzi analitycznych. Jeśli masz ukończone 18
            lat, chcielibyśmy kierować do Ciebie spersonalizowane reklamy,
            atrakcyjne oferty oraz rabaty dopasowane do Twoich preferencji i
            zainteresowań, które mogą wpływać na Twoje decyzje zakupowe. Jeśli
            wybierzesz opcję „Zgadzam się”, będziemy stosować pliki cookies, a
            Twoje dane mogą zostać przekazane naszym zaufanym partnerom. Jeśli
            odmówisz, wykorzystamy tylko niezbędne pliki cookies do poprawnego
            funkcjonowania Strony. Wybierz “Ustawienia preferencji”, i dokonaj
            szczegółowych wyborów. Masz pytania ? Więcej informacji znajdziesz w
            naszej Polityce cookies i Polityce prywatności.
          </p>
          <div className="modal-card-foot">
            <button
              className="btn-secondary button"
              onClick={handleChangeCookies}
            >
              Ustawienia preferencji
            </button>
            <button
              className="btn-primary button"
              onClick={handleCookiesSubmit}
            >
              Zgadzam się
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
