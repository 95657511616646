import axios from "axios";

export const apiMongo = axios.create({
  baseURL: `${process.env.REACT_APP_MONGO_URL}`,
  withCredentials: true,
  // headers: {
  //   "Access-Control-Allow-Origin":
  //     "https://map-game-server-1cb5.onrender.com/api/v1",
  // },

  // do czego w ogole potrzebuje headers w real app?
  //withCredentials i headers blokuja MailerLite
  // brak withCredentials i headers blokuje dostęp do profilu Admin'a
});

//withCredentials i headers blokuja MailerLite

export const apiMailerLite = axios.create({
  baseURL: "https://connect.mailerlite.com/api", // Podstawowy URL dla MailerLite API
});
