import { useGameContext } from "../context/useGameContext";
import { CookiesModal } from "../components/CookiesModal";
import { CookiesModalPreferences } from "../components/CookiesModalPreferences";
import { NewsletterSubscribeModal } from "../components/NewsletterSubscribeModal";
import { NewsletterSuccessModal } from "../components/NewsletterSuccessModal";
import { Link } from "react-router-dom";
import ButtonPlay from "../components/ButtonPlay";

export const WinnersPage = () => {
  const { winnerImages } = useGameContext();

  // Oblicz liczbę wierszy, zaczynając od 4 elementu
  const numRows = Math.ceil((winnerImages?.length - 3) / 4);

  // Generuj tablicę indeksów wierszy
  const rowIndices = Array.from({ length: numRows }, (_, index) => index);

  return (
    <div className="section winnersPage">
      <nav className="level">
        <div className="level-item has-text-centered">
          <Link to="/lobby">
            <ButtonPlay title="Dołącz do gry" />
          </Link>
        </div>
      </nav>
      <div className="tile is-ancestor">
        {/* <div className="tile is-parent ">
          <article className="tile is-child notification">
            <figure>
              <img src={require("../assets/images/puppy.jpg")} alt="winner1" />
            </figure>
            <p className="title">2</p>
            <p className="subtitle">Zgarnął jakąś tam nagrodę</p>
            <p>Wygrał XXX PLN</p>
            <p>Styczeń 12.01.2000</p>
          </article>
        </div> */}
        {winnerImages?.slice(0, 3).map((image: any, index: any) => (
          <div key={index} className="tile is-parent">
            <article className="tile is-child notification">
              <figure>
                <img src={image.url} alt={`winner${index + 1}`} />
              </figure>
              <p className="title is-5">{image.tags.name}</p>
              <p className="subtitle is-6">{image.tags.opis}</p>
              <p className="text-is-3">{image.tags.nagroda}</p>
              <p className="">{image.tags.data}</p>
            </article>
          </div>
        ))}
      </div>
      {rowIndices.map((rowIndex) => {
        const rowItems = winnerImages.slice(
          3 + rowIndex * 4,
          3 + (rowIndex + 1) * 4
        );
        const emptySlots = 4 - rowItems.length;

        return (
          <div key={rowIndex} className="tile is-ancestor">
            {rowItems.map((image: any, index: any) => (
              <div key={index} className="tile is-parent">
                <article className="tile is-child notification">
                  <figure>
                    <img
                      src={image.url}
                      alt={`winner${index + 4 * rowIndex + 4}`}
                    />
                  </figure>
                  <p className="title is-5 ">{image.tags.name}</p>
                  <p className="subtitle is-6">{image.tags.opis}</p>
                  <p className="">{image.tags.nagroda}</p>
                  <p className="">{image.tags.data}</p>
                </article>
              </div>
            ))}
            {Array.from({ length: emptySlots }).map((_, index) => (
              <div key={`empty-${index}`} className="tile is-parent">
                <article className="tile is-child notification"></article>
              </div>
            ))}
          </div>
        );
      })}
      <CookiesModal />
      <CookiesModalPreferences />

      <NewsletterSubscribeModal />
      <NewsletterSuccessModal />
    </div>
  );
};
