// JESLI JEST DISCOUNT CODE TO MUSI BYC TRZYMANY W STANIE,
// MOZE PRZENIESC TO DO CONTEXT I DODAC DO Local Stroage?

import { useEffect, useState } from "react";
import { useGameContext } from "../context/useGameContext";
import {
  PlayerDataTypes,
  // paymentType,
  agrementsTypes,
} from "../types/userDataTypes";
import { formatCurrency } from "../utilities/formatCurrency";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodCartSchema } from "../models/zodFormModel";
import { zodResolver } from "@hookform/resolvers/zod";
import { Link } from "react-router-dom";
import { CartSummaryModal } from "../components/CartSummaryModal";
import { CartForm } from "../components/CartForm";
import { apiMongo } from "../api/apiData";
import moment from "moment-timezone";
import ReactDOMServer from "react-dom/server";
import { subscribeToNewsletter } from "../utilities/subscribeToNewsletter";
import { toast } from "react-toastify";
import OrderConfirmationEmail from "../emails/OrderConfirmation";
import { ShelterElement } from "../components/ShelterBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaw } from "@fortawesome/free-solid-svg-icons";
moment.locale("pl");

export const Cart = () => {
  const {
    markers,
    cartValue,
    playerData,
    setPlayerData,
    addNewOrder,
    discountCode,
    setDiscountCode,
    discountPercent,
    setDiscountPercent,
    ip,
    gameSetup,
  } = useGameContext();

  const [summaryModal, setSummaryModal] = useState<boolean>(false);

  const [loadingDiscount, setLoadingDiscount] = useState(false);
  const [loadingCartSubmission, setLoadingCartSubmission] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    watch,
    reset,
    trigger,
    control,
    setValue,
  } = useForm<PlayerDataTypes & agrementsTypes>({
    resolver: zodResolver(zodCartSchema),
    mode: "onBlur",
    reValidateMode: "onSubmit",

    defaultValues: {
      firstName: playerData.firstName,
      lastName: playerData.lastName,
      email: playerData.email,
      phone: playerData.phone,
      city: playerData.city,
      purchaseAgreements: false,
      newsletterAgreement: false,
    },
  });

  const sendOrderConfirmation = async (orderId: string) => {
    const userEmail = playerData.email;
    const firstName = playerData.firstName;
    const orderNumber = orderId.toString();
    const gameName = gameSetup.name;
    const endDate = moment(new Date(gameSetup.endDate))
      .tz("Europe/Warsaw")
      .locale("pl")
      .format("D MMMM YYYY HH:mm");

    const orderEmailHtml = ReactDOMServer.renderToString(
      <OrderConfirmationEmail
        firtName={firstName}
        orderNumber={orderNumber}
        gameName={gameName}
        orderValue={cartValue}
        quantity={markers.length}
        endDate={endDate}
      />
    );

    await apiMongo.post(`${process.env.REACT_APP_MONGO_URL}/email/order`, {
      orderEmailHtml,
      firstName,
      userEmail,
    });
  };

  const onSubmit: SubmitHandler<PlayerDataTypes> = async () => {
    try {
      setLoadingCartSubmission(true);

      //dodaj zamówienie do bazy
      const orderData = await addNewOrder();

      //wyślij maila z potwierdzeniem zamówienia
      await sendOrderConfirmation(orderData._id);

      //zacznij płatność i przenieś do linku AutoPay
      try {
        const response = await apiMongo.post(
          `${process.env.REACT_APP_MONGO_URL}/payment/create_payment`,
          {
            orderId: orderData._id,
            amount: orderData.total_price,
            customerEmail: playerData.email,
            gameEndDate: gameSetup.endDate,
          }
        );
        const paymentUrl = response.data.paymentUrl;
        window.location.href = paymentUrl;
      } catch (error) {
        console.error("Błąd inicjalizacji płatności:", error);
      }
    } catch (error) {
      console.error("Błąd przy składaniu zamówienia:", error);
      setLoadingCartSubmission(false);
    }

    if (newsletterAgreement) {
      try {
        const { firstName: userFirstName, email: userEmail } = playerData;
        const response = await subscribeToNewsletter(
          { userFirstName, userEmail },
          ip
        );
        if (response && (response.status === 200 || 201 || 202)) {
          toast.success("Zapisałeś sie do newslettera, potwierdź maila!");
        } else {
          toast.error("Nie udało się zapisać Ciebie do newsletter'a");
        }
      } catch (error) {
        toast.error("Nie udało się zapisać Ciebie do newsletter'a");
      }
    }

    // wszystkie stany gry powinny sie resetować, jak przejdziemy do zewnętrznego linku z płatnością
    // ktore działa?
    // sessionStorage.clear();
    // sessionStorage.removeItem("discountData");
    // // setCartValue(0);
    // setMarkers([]);
    // setDiscountPercent(0);
    // setDiscountCode("");
    // setPlayerData({
    //   firstName: "",
    //   lastName: "",
    //   email: "",
    //   phone: "",
    //   city: "",
    //   purchaseAgreements: false,
    //   newsletterAgreement: false,
    // });
    // setLoadingCartSubmission(false);
    // setSummaryModal(false);
    // sessionStorage.clear();
  };

  const closeSummaryModal = () => {
    setSummaryModal(false);
  };

  const [
    firstName,
    lastName,
    email,
    phone,
    city,
    purchaseAgreements,
    newsletterAgreement,
    allAgreements,
  ] = watch([
    "firstName",
    "lastName",
    "email",
    "phone",
    "city",
    "purchaseAgreements",
    "newsletterAgreement",
    "allAgreements",
  ]);

  useEffect(() => {
    if (allAgreements) {
      setValue("purchaseAgreements", true);
      setValue("newsletterAgreement", true);
    } else {
      setValue("purchaseAgreements", false);
      setValue("newsletterAgreement", false);
    }
  }, [allAgreements, setValue]);

  useEffect(() => {
    setPlayerData((prev) => ({
      ...prev,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      city: city,
      purchaseAgreements: purchaseAgreements,
      newsletterAgreement: newsletterAgreement,
    }));
  }, [
    firstName,
    lastName,
    email,
    phone,
    city,
    setPlayerData,
    purchaseAgreements,
    newsletterAgreement,
  ]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  //sprawdź czy pola są wypełnione poprawnie
  const openSummary = () => {
    const validateFormFields = trigger();
    validateFormFields.then((value) => {
      if (value === true) {
        setSummaryModal(true);
      }
    });
  };

  const checkDiscountCode = async () => {
    try {
      setLoadingDiscount(true);
      const response = await apiMongo.post(
        `${process.env.REACT_APP_MONGO_URL}/game/checkdiscount`,
        { code: discountCode }
      );

      if (response.status === 200) {
        // console.log(response.data);

        // Update state related to the discount code and percentage
        setDiscountPercent(Number(response.data.percent));
        setLoadingDiscount(false);

        // console.log({
        //   percent: Number(response.data.percent),
        //   _id: response.data._id,
        // });
      }
    } catch (error) {
      // console.log("Discount code is not valid anymore");
      console.log("Error validation of dicsount code", error);
      toast.warning("Kod nie jest aktualny");
      setLoadingDiscount(false);
    }
  };

  const deleteDiscountCodeFromUsage = async () => {
    // console.log("discountCode:", discountCode);
    try {
      setLoadingDiscount(true);
      const response = await apiMongo.patch(
        `${process.env.REACT_APP_MONGO_URL}/game/discount`,
        {
          code: discountCode,
        }
      );

      if (response.status === 200) {
        setDiscountCode("");
        setDiscountPercent(0);
        sessionStorage.removeItem("discountData");
        setLoadingDiscount(false);
      }
    } catch (error) {
      console.log("Error deleting discount code");
    }
  };

  const discountedValue = (cartValue * 100) / (100 - discountPercent);

  return (
    <section className="hero heroNotGame">
      {/* <button onClick={() => sendOrderConfirmation()}>Send Email</button> */}
      <div className="hero-head font-antonio">
        <div className="mr-2 ml-2">
          <p>✨ Łączymy przyjemne z pożytecznym!</p>
          <p>
            Ty masz okazję zgarnąć nagrodę i przy okazji zrobić coś dobrego dla
            bezdomnych zwierzaków!
            <FontAwesomeIcon icon={faPaw} style={{ marginLeft: "8px" }} />
          </p>
        </div>
      </div>
      <div className="hero-body">
        {cartValue <= 0 ? (
          <div className="box">
            <h1 className="title is-5">
              Wygląda na to, że Twój koszyk jest pusty
            </h1>
            <p>Odważysz się wziąć udział?</p>
            <br />
            <Link to="/game">
              <button className="button is-primary">Zagraj!</button>
            </Link>
          </div>
        ) : (
          <div className="columns cartFormColumns">
            <div className="column">
              <CartForm
                register={register}
                errors={errors}
                openSummary={openSummary}
              />
            </div>

            <div className="column">
              <div className="box">
                <p className="title is-5 font-antonio">
                  <strong>Podsumowanie koszyka</strong>
                </p>
                <p className="mb-4">
                  Opłata wpisowa ilość: <strong>{markers.length}</strong>
                </p>

                {discountPercent > 0 && (
                  <div>
                    <h2 style={{ textDecoration: "line-through" }}>
                      Stara cena: {formatCurrency(discountedValue)}
                    </h2>
                    <strong>Rabat: -{discountPercent}% </strong>
                  </div>
                )}
                <p className="mb-4">
                  Do zapłaty: <strong>{formatCurrency(cartValue)}</strong>
                </p>

                <div className="field">
                  <label className="label">Posiadasz kod zniżkowy?</label>
                  <div>
                    <div className="control mb-2">
                      <input
                        type="text"
                        className="input"
                        placeholder="Kod zniżkowy"
                        value={discountCode}
                        disabled={discountPercent > 0 || loadingDiscount}
                        onChange={(e) => {
                          setDiscountCode(e.target.value);
                        }}
                      />
                    </div>
                    <div className="control">
                      <button
                        className="button"
                        onClick={checkDiscountCode}
                        disabled={discountPercent > 0}
                      >
                        Dodaj kod zniżkowy
                      </button>
                    </div>
                  </div>
                  {loadingDiscount ? <span className="loader"></span> : <></>}
                  {discountPercent > 0 && (
                    <div className="block is-flex is-align-items-center is-justify-content-center">
                      <span className="tag is-danger">
                        {discountCode} -{discountPercent}%
                        <button
                          className="delete is-medium is-warning is-danger"
                          onClick={deleteDiscountCodeFromUsage}
                          disabled={loadingDiscount}
                        >
                          {discountCode}
                        </button>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <br />
        <ShelterElement />
      </div>

      <CartSummaryModal
        summaryModal={summaryModal}
        closeSummaryModal={closeSummaryModal}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        loadingCartSubmission={loadingCartSubmission}
      />
    </section>
  );
};
