import {
  Body,
  Container,
  Column,
  Head,
  Html,
  Img,
  Preview,
  Row,
  Section,
  Text,
} from "@react-email/components";

//link logo ze strony produkcyjnej - poniższe nie działa
const logo = `https://mapgame.pl/logoWithBlackText.png`;

export default function OrderConfirmationEmail({
  firstName,
  orderNumber,
  gameName,
  orderValue,
  quantity,
  endDate,
}: any) {
  return (
    <Html>
      <Head />
      <Preview>Potwierdzenie złożenia zamówienia</Preview>

      <Body style={main}>
        <Container style={container}>
          <Section>
            <Row>
              <Column style={centeredColumn}>
                <Img
                  src={logo}
                  width="370"
                  height="90"
                  alt="Map Game"
                  style={centeredImage}
                />
              </Column>
            </Row>
          </Section>
          <Section>
            <Text style={boldText}>Cześć {firstName},</Text>
            <Text style={bodyText}>
              Dziękuję za złożenie zamówienia, numer Twojego zamówienia to{" "}
              <strong>{orderNumber}</strong>.
            </Text>
            <Text style={boldText}>Zamówienie zawiera:</Text>
            <Text style={bodyText}>
              Wpisowe w konkursie Map Game {gameName} - ilość {quantity}.
            </Text>
            <Text style={bodyText}>Wartość zamówienia: {orderValue} zł</Text>
            <Text style={boldText}>Konkurs kończy się: {endDate}</Text>
            <Text style={bodyText}>
              Jeśli z jakichś przyczyn nie udało się Tobie opłacić zamówienia,
              link do płatności, to znajdziesz go w mailu od bramki płatniczej
              AutoPay.
            </Text>
            <Text style={bodyText}>
              Możesz wybrać różne rodzaje płatności, w tym blik, przelew
              natychmiastowy czy karta płatnicza.
            </Text>
            <Text style={footerText}>Powodzenia w zabawie!</Text>
            <Text style={signatureText}>Miłego dnia</Text>
            <Text style={signatureTextBold}>Maciek z MapGame</Text>
          </Section>
        </Container>
      </Body>
    </Html>
  );
}

const main = {
  fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
  backgroundColor: "#ffffff",
};

const container = {
  margin: "0 auto",
  padding: "20px",
  width: "600px",
  maxWidth: "100%",
};

const centeredColumn = {
  textAlign: "center" as const,
};

const centeredImage = {
  display: "block",
  margin: "20px auto",
};

const boldText = {
  marginTop: "16px",
  fontSize: "16px",
  fontWeight: "bold",
};

const bodyText = {
  fontSize: "16px",
};

const footerText = {
  fontSize: "14px",
  marginBottom: "20px",
  textAlign: "center" as const, // Align text to the center
};

const signatureText = {
  fontSize: "16px",
  marginBottom: "5px",
  textAlign: "right" as const, // Align text to the right
};

const signatureTextBold = {
  fontSize: "16px",
  fontWeight: "bold",
  textAlign: "right" as const, // Align text to the right
};
