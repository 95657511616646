import { useState, useEffect, useMemo } from "react";
import { useGameContext } from "../context/useGameContext";
import moment, { Moment } from "moment-timezone";
moment.locale("pl");

interface CountdownProps {
  endDate: Moment;
}

const Countdown = ({ endDate }: CountdownProps) => {
  const SECOND = 1000;
  const MINUTE = SECOND * 60;
  const HOUR = MINUTE * 60;
  const DAY = HOUR * 24;

  const getTimeLeft = () => {
    if (!endDate.isValid()) {
      return 0;
    }
    return endDate.valueOf() - Date.now();
  };

  const [time, setTime] = useState(getTimeLeft());

  useEffect(() => {
    const interval = setInterval(() => setTime(getTimeLeft()), 1000);
    return () => clearInterval(interval);
  }, [endDate]);

  const remainingTime = time > 0 ? time : 0;

  const timeComponents = useMemo(() => {
    return {
      Dni: Math.floor(remainingTime / DAY),
      Godzin: Math.floor((remainingTime / HOUR) % 24),
      Minut: Math.floor((remainingTime / MINUTE) % 60),
      Sekund: Math.floor((remainingTime / SECOND) % 60),
    };
  }, [remainingTime]);

  return (
    <div className="timer columns is-gapless font-antonio">
      {Object.entries(timeComponents).map(([label, value]) => (
        <div key={label} className="column">
          <p className="timer-digits font-antonio">
            {`${value}`.padStart(2, "0")}
          </p>
          <span className="timer-label">{label}</span>
        </div>
      ))}
    </div>
  );
};

export const Timer = () => {
  const { gameSetup } = useGameContext();

  const initialEndDate = gameSetup.endDate
    ? moment(gameSetup.endDate).tz("Europe/Warsaw")
    : moment().tz("Europe/Warsaw");

  const [endDate, setEndDate] = useState(initialEndDate);

  useEffect(() => {
    if (gameSetup.endDate) {
      setEndDate(moment(gameSetup.endDate).tz("Europe/Warsaw"));
    }
  }, [gameSetup.endDate]);

  return (
    <div className="timer-box">
      <p className="timer-text is-uppercase mb-5 font-antonio">
        Koniec aktualnego konkursu za
      </p>
      <Countdown endDate={endDate} />
    </div>
  );
};
