import { Link } from "react-router-dom";
import ButtonPlay from "./ButtonPlay";
import { useGameContext } from "../context/useGameContext";
import { useEffect, useState } from "react";

export const HomeBanner = () => {
  const { isGameRunning } = useGameContext();

  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 1024);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="hero-overlay"></div>
      <img
        className="home-image-banner"
        src={require("../../src/assets/backgrounds/bg-banner.png")}
        alt="home banner"
      />
      <div className="section text-on-banner">
        <div className="font-anton has-text-white banner-text">
          Znajdź miejsce na mapie
        </div>
        {/* <div className="font-anton has-text-white banner-text2">na mapie</div> */}

        <div
          className="font-anton title has-text-white "
          style={{
            fontSize: "4.5rem",
            letterSpacing: "4px",
          }}
        >
          i zgarnij
        </div>
        <div
          className="font-anton title has-text-warning text-color"
          style={{
            marginBottom: "3rem",
            fontSize: "5rem",
            letterSpacing: "4px",
          }}
        >
          500 PLN!
        </div>
        <br />
        {isGameRunning ? (
          <Link to="/lobby">
            <ButtonPlay title="Dołączam do wyzwania" />
          </Link>
        ) : (
          <Link to="/nogame">
            <ButtonPlay title="Dołączam do wyzwania" />
          </Link>
        )}
      </div>
    </>
  );
};
