import { useContext } from "react";
import TurnstileContext from "./TurnstileContext";

export const useTurnstileContext = () => {
  const context = useContext(TurnstileContext);

  if (!context) {
    throw new Error(
      "useTurnstileContext must be used within a TurnstileProvider"
    );
  }

  return context;
};
