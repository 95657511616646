import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGameContext } from "../context/useGameContext";

export const WinnersElement = () => {
  const navigate = useNavigate();
  const { winnerImages } = useGameContext();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="">
      <div className="tile is-ancestor">
        <div className="tile is-parent is-6">
          <article className="tile is-child notification has-text-left is-12 description-article ">
            <div className="subtitle is-3 font-anton">
              POZNAJ NASZYCH OSTATNICH ZWYCIĘZCÓW
            </div>

            <div className="content">
              <p>Dołącz i wygrywaj z nami kasę lub inne nagrody!</p>
            </div>
            <button
              className="button is-warning winners-button"
              onClick={() => navigate("/zwyciezcy")}
            >
              Zobacz wszystkich...
            </button>
          </article>
        </div>

        {winnerImages?.slice(0, 2).map((image: any, index: any) => (
          <div key={index} className="tile is-parent ">
            <article className="tile is-child notification">
              <figure>
                <img src={image.url} alt={`winner${index + 1}`} />
              </figure>
              <p className="title">{image.tags.name}</p>
              <p className="subtitle">{image.tags.opis}</p>
              <p className="">{image.tags.nagroda}</p>
              <p className="">{image.tags.data}</p>
            </article>
          </div>
        ))}
      </div>

      {!isMobile && (
        <div className="tile is-ancestor">
          {winnerImages?.slice(2, 6).map((image: any, index: any) => (
            <div key={index} className="tile is-parent ">
              <article className="tile is-child notification">
                <figure>
                  <img src={image.url} alt={`winner${index + 1}`} />
                </figure>
                <p className="title">{image.tags.name}</p>
                <p className="subtitle">{image.tags.opis}</p>
                <p className="">{image.tags.nagroda}</p>
                <p className="">{image.tags.data}</p>
              </article>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
