import { Link, useNavigate } from "react-router-dom";
import { WorldMap } from "./WorldMap";
import GoogleStreetView from "./GoogleStreetView";
import { useGameContext } from "../context/useGameContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { formatCurrency } from "../utilities/formatCurrency";
import { useEffect } from "react";

export const GamePage = () => {
  const { markers, cartValue, isGameRunning } = useGameContext();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0); // Przewija do góry strony
  }, []);

  useEffect(() => {
    if (!isGameRunning) {
      navigate("/");
    }
  }, [isGameRunning, navigate]);

  return (
    <section className="hero">
      <div>
        <div className="heroGame">
          <GoogleStreetView />
        </div>
        <div>
          <WorldMap />
        </div>
      </div>
      <br />
      <div className="game-buttons">
        <Link to="/cart">
          <button
            className={`button is-primary is-fullwidth is-medium
            ${markers.length <= 0 ? "is-disabled" : ""}`}
            disabled={markers.length <= 0}
          >
            <span className="icon is-small">
              <FontAwesomeIcon icon={faCheck} />
            </span>
            <span>Wyślij odpowiedzi</span>
          </button>
        </Link>
        <button className="button is-fullwidth is-medium">
          <p className="">Wartość koszyka: {formatCurrency(cartValue)}</p>
        </button>
      </div>
    </section>
  );
};
