import { Navigate } from "react-router-dom";
import { useAuthContext } from "../context/useAuthContext";

export const ProtectedAdminRoute = ({ children, ...rest }: any) => {
  const { userInfo } = useAuthContext();

  if (!userInfo || !userInfo.isAdmin) {
    return <Navigate to="/admin" replace />;
  }
  return children;
};
