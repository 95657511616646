import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes, useLocation } from "react-router-dom";
import { TurnstileProvider } from "./context/TurnstileContext";
import { GameProvider } from "./context/GameContext";
import { AuthProvider } from "./context/AuthContext";
import { CookieProvider } from "./context/CookieContext";
import { Navbar } from "./components/Navbar";
import { Footer } from "./components/Footer";
import { TurnstilePage } from "./pages/TurnstilePage";
import { Home } from "./pages/HomePage";
import { AboutPage } from "./pages/AboutPage";
import { LobbyPage } from "./pages/LobbyPage";
import { GamePage } from "./components/GamePage";
import { WinnersPage } from "./pages/WinnersPage";
import { Cart } from "./pages/CartPage";
import { NotFound } from "./pages/NotFoundPage";
import { NoGame } from "./pages/NoGame";
import { AdminLogin } from "./components/AdminContent/AdminLogin";
import { AdminDashboardLayout } from "./components/AdminContent/AdminDashboardLayout";
import { Results } from "./components/AdminContent/Results";
import { Orders } from "./components/AdminContent/Orders";
import { Players } from "./components/AdminContent/Players";
import { Games } from "./components/AdminContent/Games";
import { AddWinner } from "./components/AdminContent/AddWinner";
import { DiscountCode } from "./components/AdminContent/DiscountCode";
import { ProtectedTurnstileRoute } from "./components/ProtectedTurnstileRoute";
import { ProtectedAdminRoute } from "./components/ProtectedAdminRoute";
import { DocumentsUpload } from "./components/AdminContent/DocumentsUpload";
import { useEffect } from "react";
import { StatuePage } from "./pages/StatuePage";
import { PolicyPage } from "./pages/PolicyPage";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import moment from "moment";
import { PaymentReturn } from "./pages/PaymentReturn";

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname }); // Za każdym razem, gdy zmienia się trasa, wysyłamy hit do Google Analytics

    if (location.pathname === "/game") {
      ReactPixel.trackCustom("GamePageVisited", {
        page: location.pathname,
        timestamp: moment().tz("Europe/Warsaw"),
      });
    }
  }, [location]);
};

function App() {
  usePageTracking();

  useEffect(() => {
    ReactPixel.init(`${process.env.REACT_APP_FB_PIXEL}`); // Zastąp "TWÓJ_PIXEL_ID" rzeczywistym ID Piksela
    ReactPixel.pageView(); // Rejestruje pierwsze wyświetlenie strony
  }, []);

  return (
    <TurnstileProvider>
      <GameProvider>
        <AuthProvider>
          <CookieProvider>
            <ToastContainer />
            <div className="App">
              <header>
                <Navbar />
              </header>
              <div>
                <Routes>
                  <Route path="/verification" element={<TurnstilePage />} />
                  <Route element={<ProtectedTurnstileRoute />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/lobby" element={<LobbyPage />} />
                    <Route path="/zwyciezcy" element={<WinnersPage />} />
                    <Route path="/game" element={<GamePage />} />
                    <Route path="/nogame" element={<NoGame />} />
                    <Route path="/cart" element={<Cart />} />
                    <Route path="/admin" element={<AdminLogin />} />
                    <Route path="/regulamin" element={<StatuePage />} />
                    <Route path="/polityka" element={<PolicyPage />} />
                  </Route>
                  <Route path="/return" element={<PaymentReturn />} />
                  <Route
                    path="/adminDashboard"
                    element={
                      <ProtectedAdminRoute>
                        <AdminDashboardLayout />
                      </ProtectedAdminRoute>
                    }
                  >
                    <Route index element={<Results />} />
                    <Route path="orders" element={<Orders />} />
                    <Route path="players" element={<Players />} />
                    <Route path="games" element={<Games />} />
                    <Route path="discount" element={<DiscountCode />} />
                    <Route path="documents" element={<DocumentsUpload />} />
                    <Route path="winners" element={<AddWinner />} />
                    <Route path="*" element={<NotFound />} />
                  </Route>
                </Routes>
              </div>
              <footer>
                <Footer />
              </footer>
            </div>
          </CookieProvider>
        </AuthProvider>
      </GameProvider>
    </TurnstileProvider>
  );
}

export default App;
