import React from "react";
import { CookiesModal } from "../components/CookiesModal";
import { CookiesModalPreferences } from "../components/CookiesModalPreferences";

type Props = {};

export const AboutPage = (props: Props) => {
  return (
    <section className="heroNotGame">
      <h1 className="content has-text-centered">Kim jestem i co tworzę</h1>
      <p>
        Mam na imię Maciek i chciałem stworzyć coś unikatowego, zabawę, gdzie
        każdy będzie miał równą szansę na wygraną.
      </p>
      <br />
      <p>
        Pomysł zrodził się już jakiś czas temu, jednak brak czasu, środków
        zawsze być hamulcem do rozpoczęcia prac na poważnie.
      </p>
      <br />
      <h1 className="content has-text-centered">About Page</h1>
      <br />
      <br />
      <h1 className="content has-text-centered">About Page</h1>
      <br />
      <CookiesModal />
      <CookiesModalPreferences />
    </section>
  );
};
