import { useEffect, useState } from "react";
import { apiMongo } from "../../api/apiData";

type discountType = {
  _id: string;
  code: string;
  percent: number;
  maxUses: number;
  availableUses: number;
};

export const DiscountCode = () => {
  const [discount, setDiscount] = useState({
    code: "",
    percent: 0,
    maxUses: 0,
  });
  const [allDiscounts, setAllDiscounts] = useState([]);

  useEffect(() => {
    getDiscountCodes();
  }, []);

  const getDiscountCodes = async () => {
    try {
      const response = await apiMongo.get(
        `${process.env.REACT_APP_MONGO_URL}/game/discount`
      );
      setAllDiscounts(response.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const createNewCode = async () => {
    try {
      await apiMongo.post(`${process.env.REACT_APP_MONGO_URL}/game/discount`, {
        code: discount.code,
        percent: discount.percent,
        maxUses: discount.maxUses,
      });
      getDiscountCodes();
    } catch (error) {
      console.log("error", error);
    }
  };

  const deleteDiscountCode = async (promoCodedId: string) => {
    try {
      await apiMongo.delete(
        `${process.env.REACT_APP_MONGO_URL}/game/discount/${promoCodedId}`
      );
      getDiscountCodes();
    } catch (error) {
      console.log("error", error);
    }
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    createNewCode();
  };

  return (
    <section className="section">
      <div className="box">
        <form onSubmit={onSubmit}>
          <p className="title">Discount Code</p>
          <label className="label">Code</label>
          <input
            className="input"
            type="'text"
            id="code"
            name="code"
            onChange={(e) => setDiscount({ ...discount, code: e.target.value })}
          />

          <label className="label">Discount percent</label>
          <input
            className="input"
            type="'number"
            id="percent"
            name="percent"
            onChange={(e) =>
              setDiscount({ ...discount, percent: Number(e.target.value) })
            }
          />

          <label className="label">Max use of code</label>
          <input
            className="input"
            type="'number"
            id="maxUse"
            name="maxUse"
            onChange={(e) =>
              setDiscount({ ...discount, maxUses: Number(e.target.value) })
            }
          />

          <button className="button is-warning" type="submit">
            Submit Discount Code
          </button>
        </form>
      </div>
      <div>
        {allDiscounts.map((item: discountType) => (
          <div key={item._id} className="box">
            <p>
              <strong>Code:</strong> {item.code}
            </p>
            <p>
              <strong>Percent:</strong> {item.percent}%
            </p>
            <p>
              <strong>Maksymalna ilość:</strong> {item.maxUses}
            </p>
            <p>
              <strong>Pozostało:</strong> {item.availableUses}
            </p>
            <button
              className="button is-danger"
              onClick={() => deleteDiscountCode(item._id)}
            >
              Usuń
            </button>
          </div>
        ))}
      </div>
    </section>
  );
};
