import { useCookieContext } from "../context/useCookieContext";

export const CookiesModalPreferences = () => {
  const {
    isCookiesPreferenceModalOpen,
    setIsCookiesPreferenceModalOpen,
    userCookies,
    setUserCookies,
    setCookie,
  } = useCookieContext();

  const handleAcceptPreferenceCookies = () => {
    const preferences = {
      necessary: userCookies.necessary,
      analytics: userCookies.analytics,
      marketing: userCookies.marketing,
    };

    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

    setCookie("userConsent", JSON.stringify(preferences), {
      path: "/",
      expires: oneYearFromNow,
    });

    setIsCookiesPreferenceModalOpen(false);
  };

  const handleAcceptAllCookies = () => {
    const preferences = {
      necessary: true,
      analytics: true,
      marketing: true,
    };

    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

    setCookie("userConsent", JSON.stringify(preferences), {
      path: "/",
      expires: oneYearFromNow,
    });

    setIsCookiesPreferenceModalOpen(false);
  };

  return (
    <div>
      <section
        className={`modal ${isCookiesPreferenceModalOpen ? "is-active" : ""}`}
      >
        <div
          className="modal-background"
          onClick={() => {
            setIsCookiesPreferenceModalOpen(false);
          }}
        ></div>
        <div className="modal-card">
          <div className="modal-card-head">
            <p className="modal-card-title">Ustawienia Cookies</p>
          </div>
          <div className="modal-card-body">
            <table className="table is-bordered is-fullwidth">
              <thead>
                <tr>
                  <th title="is-size-7" className="is-size-7">
                    Rodzaj
                  </th>
                  <th title="is-size-7" className="is-size-7">
                    Opis
                  </th>
                  <th title="is-size-7" className="is-size-7">
                    Włącz/Wyłącz
                  </th>
                </tr>
              </thead>

              <tbody className="is-size-7">
                <tr>
                  <td>Niezbędne</td>
                  <td>
                    Cookies niezbędne do prawidłowego funkcjonowania witryny.
                    Umożliwiają utrzymanie sesji użytkownika, obsługę
                    formularzy, zapamiętywanie preferencji oraz zapewnienie
                    bezpieczeństwa strony.
                  </td>
                  <td>
                    <div className="field">
                      <input
                        id="switchRoundedDefault"
                        type="checkbox"
                        name="switchColorDefault"
                        className="switch is-rounded"
                        defaultChecked
                        disabled
                      />
                      <label htmlFor="switchRoundedDefault"></label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Cookies analityczne i statystyczne</td>
                  <td>
                    Cookies pozwalające na zbieranie informacji o korzystaniu z
                    naszej strony, niezbędne do jej optymalizacji i prowadzenia
                    statystyk. Brak zgody może ograniczyć funkcjonalność.
                  </td>
                  <td>
                    <div className="field">
                      <input
                        id="switchRoundedDefault1"
                        type="checkbox"
                        name="switchColorDefault1"
                        className="switch is-rounded"
                        onChange={(e) => {
                          setUserCookies((prev) => ({
                            ...prev,
                            analytics: e.target.checked,
                          }));
                        }}
                        defaultChecked
                      />
                      <label htmlFor="switchRoundedDefault1"></label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Reklamowe</td>
                  <td>
                    Cookies pozwalające na dostosowanie treści reklamowych do
                    preferencji użytkownika, remarketing oraz mierzenie
                    efektywności kampanii. Brak zgody nie wpływa na
                    funkcjonowanie strony.
                  </td>
                  <td>
                    <div className="field">
                      <input
                        id="switchRoundedDefault2"
                        type="checkbox"
                        name="switchColorDefault2"
                        className="switch is-rounded"
                        onChange={(e) => {
                          setUserCookies((prev) => ({
                            ...prev,
                            marketing: e.target.checked,
                          }));
                        }}
                        defaultChecked
                      />
                      <label htmlFor="switchRoundedDefault2"></label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="modal-card-foot">
              <button
                className="btn-secondary button"
                onClick={handleAcceptPreferenceCookies}
                aria-label="accept choosen cookies"
              >
                Zapisz wybrane ustawienia
              </button>
              <button
                className="btn-primary button"
                onClick={handleAcceptAllCookies}
                aria-label="accept all cookies"
              >
                Zaakceptuj wszystkie
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
