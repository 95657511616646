const ButtonPlay = ({ title }: { title: string }) => {
  return (
    <button className=" button custom-button">
      <span>
        <strong>{title}</strong>
      </span>
      <span className="icon is-small">
        <i className="fas fa-times"></i>
        <img
          src={require("../assets/logo/marker.png")}
          alt="X logo"
          className="button-custom-icon"
        />
      </span>
    </button>
  );
};

export default ButtonPlay;
