import React, { useCallback, useEffect, useRef } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";

import { useGameContext } from "../context/useGameContext";

const GoogleStreetView = () => {
  const { coor } = useGameContext();

  const { isLoaded } = useJsApiLoader({
    id: "map-script",
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`,
  });

  const panoramaRef = useRef<google.maps.StreetViewPanorama | null>(null);

  const onLoad = useCallback(() => {
    if (!panoramaRef.current) {
      panoramaRef.current = new window.google.maps.StreetViewPanorama(
        document.getElementById("map") as HTMLElement,
        {
          position: {
            lat: coor.lat / Number(process.env.REACT_APP_LAT),
            lng: coor.lng / Number(process.env.REACT_APP_LNG),
          },
          linksControl: false,
          clickToGo: false,
          enableCloseButton: false,
          disableDefaultUI: true,
          zoomControl: false,
          visible: true,
          scrollwheel: false,
          addressControl: false,
          showRoadLabels: false,
          pov: { heading: 0, pitch: 0 },
          panControl: true,

          panControlOptions: {
            position: google.maps.ControlPosition.LEFT_CENTER,
          },
          imageDateControl: false,
          disableDoubleClickZoom: true,
          motionTracking: false,
          motionTrackingControl: false,
        }
      );
    }
  }, [coor.lat, coor.lng]);

  const removeElement = () => {
    const interval = setInterval(() => {
      const element = document.querySelector('img[alt="Google"]');
      if (element) {
        element.parentElement?.remove();
        clearInterval(interval);
      }
    }, 100);
  };

  useEffect(() => {
    if (isLoaded) {
      onLoad();
      removeElement();
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        (event.key === "ArrowUp" ||
          event.key === "ArrowDown" ||
          event.key === "ArrowLeft" ||
          event.key === "ArrowRight" ||
          event.key === "+" ||
          event.key === "=" ||
          event.key === "_" ||
          event.key === "-") &&
        !event.metaKey &&
        !event.altKey &&
        !event.ctrlKey
      ) {
        event.stopPropagation();
      }
    };

    window.addEventListener("keydown", handleKeyDown, { capture: true });

    return () => {
      window.removeEventListener("keydown", handleKeyDown, { capture: true });
      panoramaRef.current = null;
    };
  }, [isLoaded, onLoad]);

  return (
    <>
      {isLoaded ? (
        <GoogleMap
          id="map"
          onLoad={onLoad}
          // onClick={(e: any) => console.log(e)}
          options={{
            disableDefaultUI: true,
          }}
        ></GoogleMap>
      ) : (
        <>
          <div className="loading">Ładowanie mapy...</div>
        </>
      )}
    </>
  );
};

export default React.memo(GoogleStreetView);
