import { useAuthContext } from "../../context/useAuthContext";

export const Players = () => {
  const { allPlayers } = useAuthContext();

  return (
    <section className="section">
      <p className="subtitle">Wszyscy gracze</p>
      <div>
        {allPlayers.map((player, index) => (
          <div key={player._id} className="reports">
            <p>
              #{index + 1} -{" "}
              <strong>
                {player.firstName} {player.lastName}
              </strong>
            </p>
            <p>
              <strong>email:</strong> {player.email} | <strong>tel:</strong>{" "}
              {player.phone}
            </p>
            <p>
              Kod:
              {player.postCode} | Miasto: {player.city} | Adres:{" "}
              {player.address}
            </p>
            <p>
              {player.creation_date} | ID: {player._id} | Newsletter:
              {player.newsletterAgreement ? " zgoda" : " brak zgody"}
            </p>
            {index < allPlayers.length - 1 && (
              <hr
                style={{
                  margin: "0.5rem",
                  border: "0.5px solid black",
                  height: "1px",
                }}
              />
            )}
          </div>
        ))}
      </div>
    </section>
  );
};
