import { Link } from "react-router-dom";

export const SmallSidebar = () => {
  return (
    <aside className="menu">
      <p className="menu-label "></p>
      <ul className="menu-list">
        <li className="has-text-link-light">
          <Link to="/adminDashboard/">Wyniki</Link>
        </li>
        <li>
          <Link to="/adminDashboard/orders">Zamówienia</Link>
        </li>
        <li>
          <Link to="/adminDashboard/players">Gracze</Link>
        </li>
        <li>
          <Link to="/adminDashboard/games">Gry</Link>
        </li>
        <li>
          <Link to="/adminDashboard/discount">Kody promo</Link>
        </li>
        <li>
          <Link to="/adminDashboard/documents">Edytor Dokumentów</Link>
        </li>
        <li>
          <Link to="/adminDashboard/winners">Zwycięzcy</Link>
        </li>
      </ul>
    </aside>
  );
};
