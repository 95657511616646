import { useState } from "react";
import { useAuthContext } from "../../context/useAuthContext";

export const Orders = () => {
  const { allOrders, allGames } = useAuthContext();

  const [selectedGame, setSelectedGame] = useState("");

  const handleGameChange = (e: any) => {
    setSelectedGame(e.target.value);
  };

  const filteredOrders = selectedGame
    ? allOrders.filter((order) => order.game_name === selectedGame)
    : allOrders;

  return (
    <section className="section">
      <p className="title is-4">Zamówienia</p>
      <p className="subtitle">Podsumowanie zamówień</p>
      <div>
        <select
          value={selectedGame}
          onChange={handleGameChange}
          className="mb-5"
        >
          <option value="">All orders</option>
          {allGames.map((game) => (
            <option key={game._id} value={game.name}>
              {game.name}
            </option>
          ))}
        </select>

        {filteredOrders.map((order: any, index: number) => (
          <div key={order._id} className="reports">
            <p>
              #{index + 1} Zamówienie: {order._id}
            </p>
            <p>Player: {order.player_id}</p>
            <p>Markers: {order.markers_id}</p>
            <p>Data złożenia: {order.order_date}</p>
            <p>Wartość: {order.total_price} PLN</p>
            <p>Metoda płatności: {order.payment_method}</p>
            <p>Opłacone: {order.isPaid ? "opłacone" : "nieopłacone"}</p>
            <p>{order.game_name}</p>
            <hr
              style={{
                margin: "0.5rem",
                border: "0.5px solid black",
                height: "1px",
              }}
            />
          </div>
        ))}
      </div>
    </section>
  );
};
