import React, { useRef, useState } from "react";
import { apiMongo } from "../../api/apiData";
import { toast } from "react-toastify";

const validFileTypes = ["image/jpg", "image/jpeg", "image/png"];

export const AddWinner = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [winnerName, setWinnerName] = useState("");
  const [winnerDesc, setWinnerDesc] = useState("");
  const [winnerDate, setWinnerDate] = useState("");
  const [winnerPrize, setWinnerPrize] = useState("");

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUpload = async (e: any) => {
    e.preventDefault();

    setIsLoading(true);

    try {
      if (!selectedFile) {
        setError("No file selected");
        setIsLoading(false);
        return;
      }
      if (!validFileTypes.includes(selectedFile.type)) {
        setError("File must be JPG/PNG format");
        setIsLoading(false);
        return;
      }

      const form = new FormData();
      form.append("image", selectedFile);
      form.append("winnerName", winnerName);
      form.append("winnerDesc", winnerDesc);
      form.append("winnerPrize", winnerPrize);
      form.append("winnerDate", winnerDate);

      await apiMongo
        .post(`${process.env.REACT_APP_MONGO_URL}/documents/images`, form)
        .then((response) => {
          console.log("Successfully added", response.data);
          toast.success("Dodany!");

          setWinnerName("");
          setWinnerDesc("");
          setWinnerPrize("");
          setWinnerDate("");
          setSelectedFile(null);
          setError("");

          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }

          setIsLoading(false);
        })
        .catch((error) => {
          setError("Błąd wysyłania danych");
          console.log("Błąd wysyłania", error);
          toast.error("Nie dodany!");
          setIsLoading(false);
        });
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  return (
    <div className="section">
      <div className="box">
        <form onSubmit={handleUpload}>
          <p className="title is-4">
            Element do dodawana zwycięzców do bazy danych, pobierani będą na
            home page
          </p>

          <label className="label" htmlFor="winnerName">
            Imię zwycięzcy
          </label>
          <input
            type="text"
            id="winnerName"
            className="input"
            onChange={(e) => setWinnerName(e.target.value)}
          />

          <label className="label" htmlFor="subtitle">
            Opis
          </label>
          <input
            type="text"
            className="input"
            id="winnerName"
            onChange={(e) => setWinnerDesc(e.target.value)}
          />

          <label className="label" htmlFor="subtitle">
            Nagroda
          </label>
          <input
            type="text"
            className="input"
            id="winnerPrize"
            onChange={(e) => setWinnerPrize(e.target.value)}
          />

          <label className="label" htmlFor="subtitle">
            Data
          </label>
          <input
            type="text"
            className="input"
            id="winnerDate"
            onChange={(e) => setWinnerDate(e.target.value)}
          />

          <label className="label" htmlFor="file">
            Zdjęcie
          </label>
          <input
            className="input"
            name="image_input"
            id="image_input"
            type="file"
            onChange={handleFileChange}
          />
          <button
            type="submit"
            className="button is-primary"
            disabled={isLoading}
          >
            Wyślij
          </button>
          {error && <p style={{ color: "#8444" }}>{error}</p>}
        </form>
      </div>
    </div>
  );
};
