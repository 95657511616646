import { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { apiMongo } from "../api/apiData";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export const StatuePage = () => {
  const [pdfBlobUrl, setPdfBlobUrl] = useState<string | null>(null);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const getStatute = async () => {
    try {
      const response = await apiMongo.get(
        `${process.env.REACT_APP_MONGO_URL}/documents/regulamin`,
        { responseType: "arraybuffer" }
      );

      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      setPdfBlobUrl(fileURL);

      return () => URL.revokeObjectURL(fileURL);
    } catch (error) {
      console.error("Error fetching PDF:", error);
    }
  };

  useEffect(() => {
    getStatute();

    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const goToNextPage = () => {
    if (currentPage < (numPages || 1)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const calculateScale = () => {
    if (windowWidth > 768) {
      return 1.5; // For larger screens
    } else if (windowWidth > 480) {
      return 1.2; // For tablets
    } else {
      return 1; // For mobile devices, smaller scale
    }
  };

  return (
    <div className="heroNotGame">
      <div className="pdf-container">
        {pdfBlobUrl ? (
          <>
            <div className="pdf-scroll-container">
              <Document file={pdfBlobUrl} onLoadSuccess={onDocumentLoadSuccess}>
                <Page
                  className="pdf-page"
                  pageNumber={currentPage}
                  scale={calculateScale()}
                />
              </Document>
            </div>

            <nav className="pdf-navigation">
              <button
                onClick={goToPreviousPage}
                disabled={currentPage <= 1}
                className="button"
              >
                Poprzednia strona
              </button>
              <div className="pdf-info">
                Strona {currentPage} z {numPages}
              </div>
              <button
                onClick={goToNextPage}
                disabled={currentPage >= (numPages || 1)}
                className="button"
              >
                Następna strona
              </button>
            </nav>
          </>
        ) : (
          <p>Ładowanie PDF...</p>
        )}
      </div>
    </div>
  );
};
