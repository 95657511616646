import { useGameContext } from "../context/useGameContext";

const NewsletterElement = ({
  title,
  description1,
  description2,
  buttonText,
}: {
  title: string;
  description1: string;
  description2: string;
  buttonText: string;
}) => {
  const { setIsNewsletterModalOpen } = useGameContext();

  return (
    <section className="section">
      <p className="title is-3 font-anton mb-6">{title}</p>
      <p className="subtitle mb-6">{description1}</p>
      <p className="subtitle mb-6 has-text-weight-semibold">{description2}</p>
      <button
        className="button is-medium is-primary mb-6 promo-button"
        onClick={() => setIsNewsletterModalOpen(true)}
      >
        {buttonText}
      </button>
    </section>
  );
};

export default NewsletterElement;
