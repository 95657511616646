import React, { useEffect, createContext, useState, ReactNode } from "react";

type TurnstileContextValue = {
  isSucessValidation: boolean;
  setIsSucessValidation: React.Dispatch<React.SetStateAction<boolean>>;
};

const TurnstileContext = createContext<TurnstileContextValue | undefined>(
  undefined
);

type TurnstileProviderTypes = {
  children: ReactNode;
};

export const TurnstileProvider: React.FC<TurnstileProviderTypes> = ({
  children,
}) => {
  const [isSucessValidation, setIsSucessValidation] = useState(false);

  useEffect(() => {
    console.log("isSucessValidation", isSucessValidation);
  }, [isSucessValidation]);

  const contextValue: TurnstileContextValue = {
    isSucessValidation,
    setIsSucessValidation,
  };

  return (
    <TurnstileContext.Provider value={contextValue}>
      {children}
    </TurnstileContext.Provider>
  );
};

export default TurnstileContext;
