import { CookiesModal } from "../components/CookiesModal";
import { CookiesModalPreferences } from "../components/CookiesModalPreferences";
import NewsletterElement from "../components/NewsletterElement";
import { NewsletterSubscribeModal } from "../components/NewsletterSubscribeModal";
import { NewsletterSuccessModal } from "../components/NewsletterSuccessModal";

export const NoGame = () => {
  return (
    <div className="heroNotGame">
      <NewsletterElement
        title="Zapisz się do listy oczekujących"
        description1="Potrzebujemy chwili na ogarnięcie kolejnej akcji!"
        description2="Między czasie, zgarnij zniżke -50% na udział w zabawie!"
        buttonText="Zapisuję się"
      />
      <CookiesModal />
      <CookiesModalPreferences />

      <NewsletterSubscribeModal />
      <NewsletterSuccessModal />
    </div>
  );
};
