import { Link, Navigate, useLocation } from "react-router-dom";
import ButtonPlay from "../components/ButtonPlay";
import { useGameContext } from "../context/useGameContext";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { apiMongo } from "../api/apiData";
import { FaInstagram } from "react-icons/fa";
moment.locale("pl");

export const PaymentReturn = () => {
  const location = useLocation();
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const { gameSetup } = useGameContext();
  const params = new URLSearchParams(location.search);
  const orderID = params.get("OrderID");

  useEffect(() => {
    if (!orderID) {
      setRedirect(true);
      return;
    }

    const isOrderPaid = async () => {
      try {
        const response = await apiMongo.get(
          `${process.env.REACT_APP_MONGO_URL}/game/order/${orderID}`
        );
        setPaymentStatus(response.data.message);
      } catch (error: any) {
        if (error.response && error.response.status === 404) {
          console.log("No payment status found");
          setPaymentStatus(false);
        } else {
          console.error(`Cannot get payment status: ${error.message}`);
        }
      }
    };

    if (orderID) {
      isOrderPaid();
    }
  }, []);

  const initialEndDate = new Date(gameSetup.endDate);
  const isEndDateValid = !isNaN(initialEndDate.getTime());
  const endDate = isEndDateValid
    ? moment(initialEndDate)
        .tz("Europe/Warsaw")
        .locale("pl")
        .format("D MMMM YYYY HH:mm")
    : null;

  if (redirect) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="heroNotGame">
      <section className="section">
        <p className="title is-3 font-anton mb-6">
          Dzięki za udział z zabawie!
        </p>

        {paymentStatus ? (
          <p className="subtitle is-5">Twoja płatność przeszła pomyślnie!</p>
        ) : (
          <p className="subtitle is-5">
            Płatność jest jeszcze procesowana, sprawdź status płatności na
            mailu!
          </p>
        )}

        <div className="subtitle ">
          {isEndDateValid ? (
            <>
              <p className="mb-2">Aktualny konkurs kończy się:</p>
              <p className="has-text-weight-semibold">{endDate}</p>
            </>
          ) : (
            <p className="has-text-weight-semibold">
              Aktualny konkurs się skończył.
            </p>
          )}
        </div>

        <div className="subtitle box font-anton">
          <p className="mb-2">
            Info o zwycięzcy i o wspraciu schroniska znajdziesz na naszym Insta!
          </p>
          <div className="is-flex is-justify-content-center">
            <a
              href="https://www.instagram.com/mapgame.pl/"
              target="_blank"
              rel="noopener noreferrer"
              className="is-flex align-items-center"
            >
              <span>MapGame</span>
              <FaInstagram size={30} className="ml-3" />
            </a>
          </div>
        </div>

        <div className="button-box-finalPage">
          <Link to="/">
            <button className="button is-primary">Wróć do głównej</button>
          </Link>
          <Link to="/game">
            <ButtonPlay title="Zagraj jeszcze raz" />
          </Link>
        </div>
      </section>
    </div>
  );
};
