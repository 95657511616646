import { useState } from "react";
import { useAuthContext } from "../../context/useAuthContext";

export const Results = () => {
  const { allMarkers, allGames, allOrders, allPlayers } = useAuthContext();
  const [selectedGameName, setSelectedGameName] = useState("");

  const calculateDistance = (
    mLat: number,
    mLng: number,
    cLat: number,
    cLng: number
  ) => {
    const street = {
      lat: cLat / Number(process.env.REACT_APP_LAT),
      lng: cLng / Number(process.env.REACT_APP_LNG),
    };

    const R = 6371e3;
    const φ1 = (street.lat * Math.PI) / 180;
    const φ2 = (mLat * Math.PI) / 180;
    const Δφ = ((mLat - street.lat) * Math.PI) / 180;
    const Δλ = ((mLng - street.lng) * Math.PI) / 180;

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = R * c; // in meters

    return d;
  };

  const handleGameNameChange = (e: any) => {
    setSelectedGameName(e.target.value);
  };

  type DistanceData = {
    containerId: string;
    markerId: string;
    gameName: string;
    distance: string;
    playerId?: string;
    isPaid?: boolean;
  };

  const renderMarkers = () => {
    const distances: DistanceData[] = [];

    allMarkers.forEach((container) => {
      container.markers.forEach((marker) => {
        const matchingGame = allGames.find(
          (game) => game.name.toLowerCase() === marker.game_name.toLowerCase()
        );

        if (matchingGame) {
          if (!selectedGameName || selectedGameName === matchingGame.name) {
            const distanceData: DistanceData = {
              containerId: container._id,
              markerId: marker._id,
              gameName: marker.game_name,
              distance: calculateDistance(
                marker.lat,
                marker.lng,
                matchingGame.lat,
                matchingGame.lng
              ).toFixed(4),
            };

            const order = allOrders.find(
              (order) => order.markers_id === container._id
            );
            if (order) {
              const player = allPlayers.find(
                (player) => player._id === order.player_id
              );

              if (player) {
                distanceData.playerId = player._id;
              }

              distanceData.isPaid = order.isPaid;
              distances.push(distanceData);
            }
          }
        }
      });
    });

    distances.sort(
      (a: DistanceData, b: DistanceData) =>
        parseFloat(a.distance) - parseFloat(b.distance)
    );

    return (
      <div>
        <select
          value={selectedGameName}
          onChange={handleGameNameChange}
          className="mb-5"
        >
          <option value="">All Games</option>
          {allGames.map((game) => (
            <option key={game._id} value={game.name}>
              {game.name}
            </option>
          ))}
        </select>

        {distances.map((distanceData, index) => (
          <div key={distanceData.markerId} className="reports">
            <p>
              {index + 1} - Gra: {distanceData.gameName}
            </p>
            <p>
              <strong>Marker distance: {distanceData.distance} meters </strong>{" "}
            </p>
            <p>Markers Cont ID: {distanceData.containerId} </p>
            <p>PlayerID: {distanceData.playerId}</p>
            <p>{distanceData.isPaid ? "opłacone" : "nieopłacone"}</p>
            <hr
              style={{
                margin: "0.5rem",
                border: "0.5px solid black",
                height: "1px",
              }}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="">
      <p className="title is-4">Wyniki</p>
      <p className="subtitle">Wyniki od najlepszych</p>
      <div>{renderMarkers()}</div>
    </div>
  );
};
