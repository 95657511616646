import { useState } from "react";
import Leaflet, { Icon } from "leaflet";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import { AddMarkers } from "./AddMarkers";
import { useGameContext } from "../context/useGameContext";
import "leaflet/dist/leaflet.css";

export const WorldMap = () => {
  const { markers, deleteLastMarker } = useGameContext();
  const [mapKey] = useState(Date.now());
  const [mapCenter, setMapCenter] = useState({
    lat: 29.360816,
    lng: -29.683553,
  });
  const [zoom, setZoom] = useState(2);
  const [isMapOpen, setIsMapOpen] = useState(false);

  const mapStyle = {
    width: "60vw",
    height: "60vh",
    borderRadius: "10px",
  };

  const corner1 = Leaflet.latLng(-90, -200);
  const corner2 = Leaflet.latLng(90, 200);
  const bounds = Leaflet.latLngBounds(corner1, corner2);

  const xIcon = require("../assets/logo/marker.png");
  const mapIcon = require("../assets/icons/icon-world-map.png");

  const customIcon = new Icon({
    iconUrl: xIcon,
    iconSize: [30, 30],
  });

  const toggleMap = () => {
    setIsMapOpen(!isMapOpen);
  };

  return (
    <div className={isMapOpen ? "world" : "world animated"}>
      <div>
        <div>
          {isMapOpen ? (
            <>
              <MapContainer
                center={mapCenter}
                zoom={zoom}
                maxZoom={13.5}
                minZoom={2}
                scrollWheelZoom={true}
                style={mapStyle}
                key={mapKey}
                maxBoundsViscosity={1.0}
                maxBounds={bounds}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <AddMarkers setMapCenter={setMapCenter} setZoom={setZoom} />
                {markers.map((marker, index) => (
                  <Marker
                    key={index}
                    position={{ lat: marker.lat, lng: marker.lng }}
                    icon={customIcon}
                  />
                ))}
              </MapContainer>
              <div className="buttons-on-map ">
                <button
                  className={`button is-warning is-focused button-revert-marker `}
                  onClick={deleteLastMarker}
                  disabled={markers.length < 0}
                >
                  Cofnij znacznik
                </button>
                <button
                  className="button is-info is-focused button-minimalize-map"
                  onClick={toggleMap}
                >
                  <span>Zminilizuj mapę</span>
                </button>
              </div>
            </>
          ) : (
            <div className="div-with-map-icon">
              <div className="button-map-close" onClick={toggleMap}>
                <img
                  src={mapIcon}
                  alt="world map"
                  className="button-map-close-icon"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
