import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pl from "date-fns/locale/pl";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { apiMongo } from "../../api/apiData";
import { useAuthContext } from "../../context/useAuthContext";
import moment from "moment-timezone";

registerLocale("pl", pl);

const initialValues = {
  name: "",
  lat: 0,
  lng: 0,
  price: 0,
  startDate: new Date(),
  endDate: new Date(),
};

const validateSchema = Yup.object({
  name: Yup.string().required("Required"),
  lat: Yup.number().moreThan(0, "Latitude required"),
  lng: Yup.number(),
  price: Yup.number().moreThan(0, "Price required, not 0"),
});

export const Games = () => {
  const { allGames, getAllGames } = useAuthContext();

  console.log("allGames", allGames);

  const deleteGame = async (gameID: string) => {
    try {
      console.log(gameID);
      await apiMongo.delete(
        `${process.env.REACT_APP_MONGO_URL}/game/newgame/${gameID}`
      );
      getAllGames();
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (values: any, actions: any) => {
    values = {
      ...values,
      lat: values.lat * Number(process.env.REACT_APP_LAT),
      lng: values.lng * Number(process.env.REACT_APP_LNG),
      // startDate: moment(values.startDate).format("DD-MM-YYYY HH:mm"),
      // endDate: moment(values.endDate).format("DD-MM-YYYY HH:mm"),
      startDate: moment(values.startDate).toISOString(),
      endDate: moment(values.endDate).toISOString(),
    };

    console.log("values", values);

    await apiMongo.post(
      `${process.env.REACT_APP_MONGO_URL}/game/newgame`,
      values
    );
    getAllGames();

    actions.resetForm();
  };

  return (
    <div className="section newGameSection">
      <div className="box">
        <h2 className="title">Dodaj Nowa Grę</h2>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validateSchema}
        >
          {(formik) => {
            return (
              <Form>
                <div className="column">
                  <label htmlFor="name" className="label">
                    Nazwa Gry
                  </label>
                  <Field type="text" id="name" name="name" className="input" />
                  <ErrorMessage name="name" />
                </div>
                <div className="field columns">
                  <div className="column">
                    <label htmlFor="lat" className="label">
                      Latitude
                    </label>
                    <Field
                      type="number"
                      id="lat"
                      name="lat"
                      className="input"
                    />
                    <ErrorMessage name="lat" />
                  </div>
                  <div className="column">
                    <label htmlFor="lng" className="label">
                      Longitude
                    </label>
                    <Field
                      type="number"
                      id="lng"
                      name="lng"
                      className="input"
                    />
                    <ErrorMessage name="lng" />
                  </div>
                </div>
                <div className="field columns">
                  <div className="column">
                    <label htmlFor="price" className="label">
                      Cena markera
                    </label>
                    <Field
                      type="number"
                      id="price"
                      name="price"
                      className="input"
                    />
                    <ErrorMessage name="price" />
                  </div>
                </div>

                <div className="field columns">
                  <div className="column">
                    <label className="label">Start Date:</label>
                    <DatePicker
                      selected={formik.values.startDate}
                      onChange={(date) =>
                        formik.setFieldValue("startDate", date)
                      }
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      timeCaption="czas"
                      dateFormat="d MMMM , yyyy h:mm aa"
                      locale="pl"
                      className="input dataPicker"
                    />
                  </div>
                  <div className="column">
                    <label className="label">End Date:</label>
                    <DatePicker
                      selected={formik.values.endDate}
                      onChange={(date) => formik.setFieldValue("endDate", date)}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      timeCaption="czas"
                      dateFormat="d MMMM , yyyy h:mm aa"
                      locale="pl"
                      className="input dataPicker"
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  className="button is-primary"
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  Wyślij
                </button>
              </Form>
            );
          }}
        </Formik>
      </div>

      <div className="box">
        <p>Przelicznik:</p>
        <p>Lat: {process.env.REACT_APP_LAT}</p>
        <p>Lng: {process.env.REACT_APP_LNG}</p>
      </div>

      <div className="box">
        {allGames.map((game, index) => (
          <div key={game._id} className="reports">
            <p>
              #{index + 1} - <strong>{game.name}</strong>
            </p>
            <p>Lat: {game.lat / Number(process.env.REACT_APP_LAT)}</p>
            <p>Lng: {game.lng / Number(process.env.REACT_APP_LNG)}</p>
            <p>Cena: {game.price}</p>
            <p>
              <strong>
                Start: {new Date(game.startDate).toLocaleString()}
              </strong>
            </p>
            <p>
              <strong>End: {new Date(game.endDate).toLocaleString()}</strong>
            </p>
            <p>ID: {game._id}</p>
            <p>
              <button onClick={() => deleteGame(game._id)}>Delete</button>
            </p>
            <hr
              style={{
                margin: "0.5rem",
                border: "0.5px solid black",
                height: "1px",
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
