import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useTurnstileContext } from "../context/useTurnstileContext";

export const ProtectedTurnstileRoute = () => {
  const { isSucessValidation } = useTurnstileContext();
  const location = useLocation();

  // WYŁĄCZONE NA CZAS BUDOWANIA STRONY
  if (isSucessValidation) {
    return <Navigate to="/verification" replace state={{ from: location }} />;
  }

  return <Outlet />;
};
