import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { useAuthContext } from "../context/useAuthContext";

type Props = {};

export const Footer = (props: Props) => {
  const location = useLocation();

  const { getPolicy, getStatute } = useAuthContext();

  const year = new Date().getFullYear();

  const logo = "/logo.png";

  let footerClass = "";

  switch (location.pathname) {
    case "/game":
      footerClass = "footer-not-display";
      break;
    case "/verification":
      footerClass = "footer-not-display";
      break;
    default:
      footerClass = "footer-default";
  }

  return (
    <footer className={`${footerClass}`}>
      <hr />
      <div className="container is-size-7">
        <div
          className="columns is-multiline footer-multiline"
          style={{ borderBottom: "1px solid,#dee2e6" }}
        >
          <div className="column is-4">
            <Link className=" is-inline-block" to="/home">
              <img className="image" src={logo} alt="logo" width="96px" />
            </Link>
            <p className=" mb-3 is-size-7">Odkrywaj i wygrywaj!</p>

            <a className="mr-3 is-inline-block" href="/">
              <FontAwesomeIcon icon={faFacebookSquare} />
            </a>

            <a className="mr-3 is-inline-block" href="/">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a className="mr-3 is-inline-block" href="/">
              <FontAwesomeIcon icon={faTiktok} />
            </a>
          </div>
          <div className="column is-8">
            <div className="columns is-multiline">
              <div className="column is-4 is-4-desktop ">
                <ul>
                  <li className="">
                    <button className="button-link" onClick={getStatute}>
                      Regulamin
                    </button>
                  </li>
                </ul>
              </div>
              <div className="column is-4 is-4-desktop">
                <ul>
                  <li className="">
                    <button className="button-link" onClick={getPolicy}>
                      Polityka Prywatności
                    </button>
                  </li>
                </ul>
              </div>
              <div className="column is-4 is-4-desktop">
                <ul>
                  <li className="">
                    <Link to="/zwyciezcy" className="button-link">
                      Zwycięzcy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className=" has-text-centered">
          <p>{`Wszelkie prawa zastrzeżone © MapGame ${year}`}</p>
        </div>
      </div>
    </footer>
  );
};
