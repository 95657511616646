import { useMapEvents } from "react-leaflet";
import { useGameContext } from "../context/useGameContext";
import "leaflet/dist/leaflet.css";

type markerTypes = {
  setMapCenter: React.Dispatch<
    React.SetStateAction<{
      lat: number;
      lng: number;
    }>
  >;
  setZoom: React.Dispatch<React.SetStateAction<number>>;
};

export const AddMarkers = ({ setMapCenter, setZoom }: markerTypes) => {
  const { markers, setMarkers, coor } = useGameContext();

  useMapEvents({
    mousemove: (e) => {
      setZoom(e.target._zoom);
      setMapCenter(e.target._lastCenter);
    },
    click: async (e) => {
      const { lat, lng } = e.latlng;
      const newMarkerWitName = {
        game_name: coor.name,
        lat: lat,
        lng: lng,
      };

      setMarkers([...markers, newMarkerWitName]);

      const center = {
        lat: newMarkerWitName.lat,
        lng: newMarkerWitName.lng,
      };

      setMapCenter(center);

      const map = e.target;
      const zoomLevel = map.getZoom();

      setZoom(zoomLevel);
    },
  });

  return <></>;
};
