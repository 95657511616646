import { Link } from "react-router-dom";

export const NotFound = () => {
  return (
    <>
      <div className="heroNotGame">
        <section className="section">
          <p className="title is-3 font-anton mb-6">Tutaj nic nie ma</p>
          <p className="subtitle">
            Przejdź do strony, której szukasz klikając w poniższy przycisk
          </p>
          <Link to="/" className="button is-primary">
            Kliknij tutaj i wróć do strony głównej
          </Link>
        </section>
      </div>
    </>
  );
};
