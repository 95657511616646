import { useState } from "react";
import { useAuthContext } from "../../context/useAuthContext";
import { apiMongo } from "../../api/apiData";

export const DocumentsUpload = () => {
  const { getPolicy, getStatute } = useAuthContext();

  const [polityka, setPolityka] = useState(null);
  const [statue, setStatue] = useState(null);

  const onPolicykaChange = (e: any) => {
    setPolityka(e.target.files[0]);
  };

  const onStatueChange = (e: any) => {
    setStatue(e.target.files[0]);
  };

  const uploadPolicy = async () => {
    if (!polityka) {
      console.log("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("politykaPlik", polityka);
    try {
      await apiMongo.post(
        `${process.env.REACT_APP_MONGO_URL}/documents/polityka`,
        formData
      );
      console.log("Document uploaded sucessfully");
    } catch (error) {
      console.log("Error uploading documnet", error);
    }
  };

  const uploadStatue = async () => {
    if (!statue) {
      console.log("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("regulaminPlik", statue);
    try {
      await apiMongo.post(
        `${process.env.REACT_APP_MONGO_URL}/documents/regulamin`,
        formData
      );
      console.log("Document uploaded sucessfully");
    } catch (error) {
      console.log("Error uploading documnet", error);
    }
  };

  return (
    <section className="section">
      <div className="box">
        <p className="subtitle">Polityka Prywatności</p>
        <input
          type="file"
          onChange={onPolicykaChange}
          name="politykaPlik"
          accept=".pdf"
        />
        <button onClick={uploadPolicy}>Wyślij dokument</button>
        <button onClick={getPolicy}>Pobierz Politykę Prywatności</button>
      </div>
      <div className="box">
        <p className="subtitle">Regulamin</p>
        <input
          type="file"
          onChange={onStatueChange}
          name="politykaPlik"
          accept=".pdf"
        />
        <button onClick={uploadStatue}>Wyślij dokument</button>
        <button onClick={getStatute}>Pobierz Regulamin</button>
      </div>
    </section>
  );
};
