// export const addUserToLocalStorage = (adminData) => {
//     localStorage.setItem('admin', JSON.stringify(adminData));
//   };

export const removeUserFromLocalStorage = () => {
  localStorage.removeItem("admin");
};

export const doNotShowNewsletterModal = () => {
  localStorage.setItem("newsletter", "true");
};

export const doNewsletterModalHasBeenShown = () => {
  return localStorage.getItem("newsletter");
};

// export const getUserFromLocalStorage = () => {
//   const result = localStorage.getItem("admin");
//   const user = result ? JSON.parse(result) : null;
//   return user;
// };
