import React, { useEffect, createContext, useState, ReactNode } from "react";
import { useCookies } from "react-cookie";

type CookieContextValueTypes = {
  isCookiesModalOpen: boolean;
  setIsCookiesModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isCookiesPreferenceModalOpen: boolean;
  setIsCookiesPreferenceModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  userCookies: {
    necessary: boolean;
    analytics: boolean;
    marketing: boolean;
  };
  setUserCookies: React.Dispatch<
    React.SetStateAction<{
      necessary: boolean;
      analytics: boolean;
      marketing: boolean;
    }>
  >;
  cookies: any;
  setCookie: any;
  removeCookie: any;
};

const CookieContext = createContext<CookieContextValueTypes | undefined>(
  undefined
);

type CookieProviderTypes = {
  children: ReactNode;
};

export const CookieProvider: React.FC<CookieProviderTypes> = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["userConsent"]);

  const [isCookiesModalOpen, setIsCookiesModalOpen] = useState(false);
  const [isCookiesPreferenceModalOpen, setIsCookiesPreferenceModalOpen] =
    useState(false);
  const [userCookies, setUserCookies] = useState({
    necessary: true,
    analytics: true,
    marketing: true,
  });

  useEffect(() => {
    const isCookiesEmpty = Object.keys(cookies).length === 0;

    if (isCookiesEmpty) {
      setIsCookiesModalOpen(true);
    } else {
      setIsCookiesModalOpen(false);
    }
  }, [cookies]);

  const contextValue: CookieContextValueTypes = {
    cookies,
    setCookie,
    removeCookie,
    isCookiesModalOpen,
    setIsCookiesModalOpen,
    isCookiesPreferenceModalOpen,
    setIsCookiesPreferenceModalOpen,
    userCookies,
    setUserCookies,
  };

  return (
    <CookieContext.Provider value={contextValue}>
      {children}
    </CookieContext.Provider>
  );
};

export default CookieContext;
