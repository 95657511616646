import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { SmallSidebar } from "./SmallSidebar";
import { AdminNavbar } from "./AdminNavbar";
import { useAuthContext } from "../../context/useAuthContext";

export const AdminDashboardLayout = () => {
  const {
    getAllGames,
    getAllPlayers,
    getAllOrders,
    getAllMarkers,
    getBannerText,
  } = useAuthContext();

  useEffect(() => {
    getAllGames();
    getAllPlayers();
    getAllOrders();
    getAllMarkers();
    getBannerText();
  }, []);

  return (
    <>
      <AdminNavbar />
      <div className="columns">
        <div className="column is-2 has-background-grey-lighter ">
          <SmallSidebar />
        </div>
        <div className="column has-background-white-ter">
          <Outlet />
        </div>
      </div>
    </>
  );
};
