import moment from "moment";
import { apiMailerLite } from "../api/apiData";

type NewsletterUserData = {
  userFirstName?: string;
  userEmail: string;
};

const addSubscriber = async (userData: NewsletterUserData, ip: string) => {
  try {
    const response = await apiMailerLite.post(
      "/subscribers",
      {
        fields: {
          name: userData.userFirstName,
        },
        email: userData.userEmail,
        groups: [`${process.env.REACT_APP_MAILERLITE_GROUP_ID}`],
        subscribed_at: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
        ip_address: ip,
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_MAILER_TOKEN}`,
        },
      }
    );
    return response.data;
  } catch (postError) {
    console.error("Błąd przy dodawaniu nowego subskrybenta", postError);
  }
};

export const subscribeToNewsletter = async (
  userData: NewsletterUserData,
  ip: string
) => {
  try {
    const checkResponse = await apiMailerLite.get(
      `/subscribers/${encodeURIComponent(userData.userEmail)}`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_MAILER_TOKEN}`,
        },
      }
    );

    if (checkResponse.data) {
      const userStatus = checkResponse.data.data.status;
      if (userStatus === "unconfirmed") {
        await addSubscriber(userData, ip);
      }
      return { status: userStatus, data: checkResponse.data };
    }
  } catch (error: any) {
    console.log("error type", typeof error);
    if (error.response && error.response.status === 404) {
      const newUserData = await addSubscriber(userData, ip);
      return { status: "newUserAdded", data: newUserData };
    } else {
      console.error("Błąd przy sprawdzaniu subskrybenta", error);
    }
  }
};
